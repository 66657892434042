import { Link } from "react-router-dom";
import URL from "../../../assets/constants/url";

const HeaderBtn = (props) => {
  const { className, text } = props;
  return (
    <div style={{ width: "100px !important" }}>
      <Link to={`/${text}`} className={className}>
        {text}
      </Link>
    </div>
  );
};

export default HeaderBtn;

export const FindPropertyDropDownBtn = (props) => {
  const { className, text } = props;
  return (
    <div style={{ width: "100px !important", color: "#fff" }}>
      <Link
        to={`${URL.SEARCH}?propertyType=${text}`}
        className={`${className} del-underLine`}
      >
        {text}
      </Link>
    </div>
  );
};
